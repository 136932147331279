import { AccountTokenPair, BaseAuthState, Jwt, Token } from '@publica/ui-common-auth'
import { Coalesce } from '@publica/utils'

import { OfficeAPI, getOfficeAPI } from './api'
import { isInOfficeApp } from './env'

export class OfficeAuthState extends BaseAuthState<Token> {
    private readonly officeApi: OfficeAPI

    constructor(host: string) {
        super({ host })
        this.officeApi = getOfficeAPI()
        void this.initialize()
    }

    private async initialize(): Promise<void> {
        await isInOfficeApp
            .then(async () => this.getOfficeAccessToken(false).catch(() => {}))
            .finally(() => {
                void this.markAsInitialized()
            })
    }

    @Coalesce
    async refreshToken(): Promise<AccountTokenPair> {
        return this.getOfficeAccessToken(false)
    }

    @Coalesce
    async login(): Promise<AccountTokenPair> {
        return this.performLogin(async () => this.getOfficeAccessToken(true))
    }

    async logout(): Promise<void> {
        return this.performLogout()
    }

    private async getOfficeAccessToken(prompt = false): Promise<AccountTokenPair> {
        if (this.accountTokenPair !== undefined && !this.accountTokenPair.token.expiresSoon()) {
            return this.accountTokenPair
        }

        return this.peformRefresh(async () => this.getNewOfficeAccessToken(prompt))
    }

    private async getNewOfficeAccessToken(prompt = false): Promise<AccountTokenPair> {
        const aToken = await this.officeApi.getAccessToken(prompt)
        const token = Jwt.withDefaultSchema(aToken)
        return this.getAccountTokenPairForToken(token)
    }
}
