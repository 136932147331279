import { Spinner as BaseSpinner, SpinnerSize } from '@fluentui/react'
import { createUseStyles } from 'react-jss'

import { FC } from '@publica/ui-common-utils'

const useStyles = createUseStyles({
    spinner: {
        paddingTop: '20%',
    },
    spinnerText: {
        marginTop: 10,
        textAlign: 'center',
    },
})

export const Spinner: FC = () => {
    const styles = useStyles()

    return (
        <div className={styles.spinner}>
            <BaseSpinner size={SpinnerSize.large}></BaseSpinner>
            <div className={styles.spinnerText}>Chargement...</div>
        </div>
    )
}
