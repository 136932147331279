import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'

import { FC } from '@publica/ui-common-utils'
import { NotInOfficePage } from '@publica/ui-office-components'
import { setEnvironment } from '@publica/ui-office-utils'

setEnvironment('main', false)

const App: FC = () => (
    <StrictMode>
        <NotInOfficePage app="Excel" />
    </StrictMode>
)

const root = createRoot(document.getElementById('container')!)

root.render(<App />)
