import { RenderableError } from '@publica/ui-common-utils'

export class InvalidDocumentError extends RenderableError {
    constructor(message = `Le document n'est pas valide`) {
        super('Document Invalide', message)
        Object.setPrototypeOf(this, InvalidDocumentError.prototype)
    }
}

export class WrongEnvironmentError extends RenderableError {
    constructor(message = `Le modèle est associé à un autre environnement`) {
        super('Environnement Invalide', message)
        Object.setPrototypeOf(this, InvalidDocumentError.prototype)
    }
}

export class UnknownOperation extends RenderableError {
    constructor(message = `L'opération n'est pas valide`) {
        super('Opération Inconnue', message)
        Object.setPrototypeOf(this, UnknownOperation.prototype)
    }
}

export class UnmetRequirementsError extends RenderableError {
    constructor(message = `La version d'Office n'est pas compatible avec cet Add-on`) {
        super(`Version d'Office Incompatible`, message)
        Object.setPrototypeOf(this, UnmetRequirementsError.prototype)
    }
}
