import { isDialog } from '../env'
import { getOfficeDialogAPI } from './dialog'
import { OfficeAPI, getOfficeHostAPI } from './host'

export * from './host'
export * from './dialog'

export const getOfficeAPI = (): OfficeAPI => {
    if (isDialog()) {
        return getOfficeDialogAPI()
    }

    return getOfficeHostAPI()
}
