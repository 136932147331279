import { createUseStyles } from 'react-jss'

import { colors, logos } from '@publica/ui-common-styles'
import { FC } from '@publica/ui-common-utils'

const useStyles = createUseStyles({
    header: {
        padding: [[20, 20, 5, 0]],
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        backgroundColor: colors.blue1,
        color: '#FFF',
    },
    main: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        alignItems: 'center',
        flex: '1 0 0',
        padding: [[10, 20]],
    },
})

type TwoToneProps = {
    title: string
}

export const TwoTone: FC<TwoToneProps> = ({ children, title }) => {
    const styles = useStyles()

    return (
        <div className="ms-welcome">
            <section className={styles.header}>
                <img width="120" height="120" src={logos.smallLogo} alt={''} />
                <h1 className="ms-fontSize-su ms-fontWeight-light">{title}</h1>
            </section>
            <main className={styles.main}>{children}</main>
        </div>
    )
}
