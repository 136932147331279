import { ResponsiveMode, SearchBox } from '@fluentui/react'
import { ISelectableOption } from '@fluentui/react'
import {
    Dropdown as BaseDropDown,
    DropdownMenuItemType,
    IDropdownOption,
    IDropdownProps,
} from '@fluentui/react/lib/Dropdown'
import { useCallback, useMemo, useState } from 'react'

import { FC } from '@publica/ui-common-utils'
import { matches } from '@publica/utils'

export interface DropDownProps extends IDropdownProps {
    searchable?: boolean
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type DropDownOption<T = any> = IDropdownOption<T>

export type DropDownOnChange = NonNullable<DropDownProps['onChange']>

type OptionRenderFn = NonNullable<IDropdownProps['onRenderOption']>

const searchHeaderKey = '__SearchHeader'
const searchHeader: IDropdownOption = { key: searchHeaderKey, text: '-', itemType: DropdownMenuItemType.Header }

const isSearchOption = (option: ISelectableOption): boolean => option.key === searchHeaderKey

export const DropDown: FC<DropDownProps> = ({ searchable = false, options, onRenderOption, onDismiss, ...props }) => {
    const [searchText, setSearchText] = useState<string>('')

    const updateSearchText = useCallback(
        (_: unknown, newValue: undefined | null | string) => (newValue ? setSearchText(newValue) : setSearchText('')),
        []
    )

    const searchOption = useMemo(
        () => <SearchBox onChange={updateSearchText} underlined={true} placeholder="Chercher" />,
        [updateSearchText]
    )

    const renderSearchOrTextOption: OptionRenderFn = useCallback(
        (option?: ISelectableOption) => {
            if (option === undefined) {
                return null
            }

            return isSearchOption(option) ? searchOption : onRenderOption ? onRenderOption(option) : <>{option.text}</>
        },
        [onRenderOption, searchOption]
    )

    const dismiss = useCallback(() => {
        setSearchText('')
        if (onDismiss !== undefined) {
            onDismiss()
        }
    }, [onDismiss])

    const shouldDisplayItem = useCallback(
        (option: ISelectableOption) => !option.disabled && matches.matchesFilter(option.text, searchText),
        [searchText]
    )

    const optionsWithSearch: IDropdownOption[] = useMemo(
        () => (searchable ? [searchHeader, ...options.filter(option => shouldDisplayItem(option))] : options),
        [options, searchable, shouldDisplayItem]
    )

    return (
        <BaseDropDown
            options={optionsWithSearch}
            responsiveMode={ResponsiveMode.large}
            onRenderOption={renderSearchOrTextOption}
            onDismiss={dismiss}
            {...props}
        />
    )
}
