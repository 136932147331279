import { atom, selector } from 'recoil'

import * as graphql from '@publica/api-graphql'
import { config } from '@publica/ui-common-utils'

import { getOfficeAPI } from './api'
import { DocumentLink } from './document'
import { isInOfficeApp } from './env'

export const inOfficeState = atom<boolean>({
    key: 'inOffice',
    default: isInOfficeApp,
})

export const documentLinkState = atom<DocumentLink | undefined>({
    key: 'documentLink',
    default: selector({
        key: 'initialDocumentLink',
        get: async ({ get }) => {
            get(inOfficeState)
            return getOfficeAPI().getDocumentLink()
        },
    }),
    effects_UNSTABLE: [
        ({ onSet, getPromise }) => {
            onSet(link => {
                void (async () => {
                    if (link === undefined) {
                        return
                    }

                    await getPromise(inOfficeState)
                    await getOfficeAPI().setDocumentLink(link)
                })()
            })
        },
    ],
})

export const isDocumentLinkedState = selector<boolean>({
    key: 'isDocumentLinked',
    get: ({ get }) => get(documentLinkState) !== undefined,
})

export const isDocumentLinkedToWrongEnvironmentState = selector<boolean>({
    key: 'isDocumentLinkedToWrongEnvironment',
    get: ({ get }) => {
        const documentEnv = get(documentLinkState)?.environment
        const appEnv = get(config.configState).environment
        return documentEnv !== undefined && documentEnv !== appEnv
    },
})

export const linkedOperationIdState = selector<string | undefined>({
    key: 'linkedOperationId',
    get: ({ get }) => get(documentLinkState)?.operationId,
})

type Group = Pick<graphql.Group, 'key' | 'name'>

type Field = Pick<graphql.Field, 'id' | 'name' | 'position' | 'group' | 'key' | 'parameters'> & {
    groups: Group[]
}

type Operation = Pick<graphql.Operation, 'id'> & {
    fields: Field[]
    groups: Group[]
}

export const linkedOperationState = atom<Operation | undefined>({
    key: 'linkedOperation',
    default: undefined,
})
