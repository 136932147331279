import { FC } from '@publica/ui-common-utils'
import { OfficeApp } from '@publica/ui-office-utils'

import { MessagePage } from '..'

type NotInOfficePageProps = {
    app: OfficeApp
}

export const NotInOfficePage: FC<NotInOfficePageProps> = ({ app }) => (
    <MessagePage>This application should be loaded in Microsoft {app} as an Add-in</MessagePage>
)
