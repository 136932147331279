import { Label, PrimaryButton, Separator } from '@fluentui/react'
import { useCallback, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useSetRecoilState } from 'recoil'

import { FC, useConfig } from '@publica/ui-common-utils'
import { DocumentLink, documentLinkState } from '@publica/ui-office-utils'
import { sort } from '@publica/utils'

import { DropDown, DropDownOnChange, DropDownOption } from '../DropDown'
import { MainFrame } from '../MainFrame'
import { Spinner } from '../Spinner'

const useStyles = createUseStyles({
    separator: {
        margin: [[15, 0]],
    },
    linkControl: {
        marginTop: 20,
        textAlign: 'right',
    },
    operationSelectLabel: {
        marginBottom: 10,
    },
})

type Operation = {
    id: string
    name: string
}

type LinkDocumentProps = {
    operations: Operation[]
    loading: boolean
    message: string
}

export const LinkDocument: FC<LinkDocumentProps> = ({ loading, operations, message }) => {
    const styles = useStyles()

    return (
        <MainFrame>
            <h3>Vous devez associer le document à une opération</h3>
            <div>{message}</div>

            <Separator className={styles.separator} />

            {loading ? <Spinner /> : <LinkDocumentForm operations={operations} />}
        </MainFrame>
    )
}

type LinkDocumentFormProps = {
    operations: Operation[]
}

const LinkDocumentForm: FC<LinkDocumentFormProps> = ({ operations }) => {
    const styles = useStyles()

    const [selectedOperationId, setSelectedOperationId] = useState<string | undefined>(undefined)
    const setDocumentLink = useSetRecoilState(documentLinkState)
    const config = useConfig()

    const operationHasBeenSelected = selectedOperationId !== undefined

    const onChange: DropDownOnChange = useCallback((_, option) => {
        if (option !== undefined) {
            setSelectedOperationId(option.key.toString())
        } else {
            setSelectedOperationId(undefined)
        }
    }, [])

    const options: DropDownOption[] = sort.naturalSortBy(
        operations.map(op => ({ key: op.id, id: op.id, text: op.name })),
        ({ text }) => text
    )

    const linkOperation = useCallback(() => {
        if (selectedOperationId !== undefined) {
            const link: DocumentLink = {
                operationId: selectedOperationId,
                environment: config.environment,
            }

            setDocumentLink(link)
        }
    }, [config.environment, selectedOperationId, setDocumentLink])

    return (
        <>
            <Label className={styles.operationSelectLabel}>Veuillez choisir une opération:</Label>
            <DropDown options={options} searchable={true} onChange={onChange} />

            <div className={styles.linkControl}>
                <PrimaryButton disabled={!operationHasBeenSelected} onClick={linkOperation}>
                    Associer
                </PrimaryButton>
            </div>
        </>
    )
}
